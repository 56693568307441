import './index.css'
import CutoutsTexture from '../cutouts-texture/'
import Menu from '../menu';

const Links = props => {
    return (
        <>
            <div className="links outskirt" style={{ color: 'white' }}>
                <CutoutsTexture
                    foreground="#E0BC38"
                    background="black"
                    style={{ width: '100vw', height: '100vh', position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, zIndex: -1, overflow: 'hidden' }} />
                <div className='links-overlaid'>
                    <Menu color="white" />
                    <div className="links-content">
                        <h2>Contact me: <a className="sinkable clickable" href="mailto://shiyu.yin@berkeley.edu">shiyu.yin@berkeley.edu</a></h2>
                        <h2><a className="sinkable clickable" href="https://www.linkedin.com/in/shiyu-yin-208bb01a4/">LinkedIn</a> / <a className="sinkable clickable" href="https://www.instagram.com/copeland_erdos/">Instagram</a></h2>
                    </div>
                </div>
            </div>
        </>

    )
}

export default Links