import { get } from 'lodash';
import { useContext, useEffect, useState } from 'react';
import './index.css'
import { PageContext } from '../pageContext';
import { folioMap } from "../works/data"
import { folioWallItems } from '../works/wall';

const Folio = props => {
    const { position = {}, speed, folioId, imgPath, width, dim, onMouseEnter, onMouseLeave } = props
    const { setActiveFolio } = useContext(PageContext);
    const folioTitle = get(folioMap, `${folioId}.title`);
    return (<div
        className={`folio-block clickable ${dim ? 'dim' : ''}`}
        style={position}
        scroll-speed={speed}
        onClick={() => setActiveFolio(folioId)}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
    >
        <img src={imgPath} style={{ width }} />
        <h4 className="small-gill">{folioTitle}</h4>
    </div>)
}

const FolioWall = props => {

    const [currentHovered, setCurrentHovered] = useState(null);

    useEffect(() => {
        const handleScroll = () => {
            document.querySelectorAll('.folio-block').forEach((block) => {
                const scrollSpeed = block.getAttribute('scroll-speed');
                const scrollTop = window.scrollY || document.documentElement.scrollTop;
                block.style.transform = `translateY(${scrollTop / scrollSpeed}px)`;
            });
        };

        window.addEventListener('scroll', handleScroll);

        // Cleanup on unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className="folio-wall-container">
            {folioWallItems.map(folio => (
                <Folio {...folio}
                    key={folio.folioId}
                    onMouseEnter={() => setCurrentHovered(folio.folioId)}
                    onMouseLeave={() => setCurrentHovered(null)}
                    dim={currentHovered && currentHovered !== folio.folioId}
                />
            ))}
        </div>
    )
}

export const FolioWallMobile = props => {
    const { setActiveFolio } = useContext(PageContext);
    return (
        <div className="folio-wall-container-mobile">
            {folioWallItems.map(folio => (
                <div
                    key={folio.folioId}
                    className={`folio-block-mobile clickable`}
                    onClick={() => setActiveFolio(folio.folioId)}
                >
                    <img src={folio.imgPath} />
                    <h4 className="small-gill">{get(folioMap, `${folio.folioId}.title`)}</h4>
                </div>
            ))}
        </div>
    )
}

export default FolioWall;

