export const folioWallItems = [
    {
        position: { left: '0vw', zIndex: 5 },
        speed: -12,
        folioId: "duplaza",
        imgPath: require("../works/duplaza/d1i.jpg"),
        width: "21vw"
    },
    {
        position: { top: '33vw', right: '0vw', zIndex: 3 },
        speed: 8,
        folioId: "agri",
        imgPath: require("../works/agri/7.jpg"),
        width: "46vw"
    },
    {
        position: { top: '10vw', right: '18vw', zIndex: 4 },
        speed: 10,
        folioId: "audi",
        imgPath: require("../works/audi/render01.jpg"),
        width: "42vw"
    },
    {
        position: { top: '26vw', left: '16vw', zIndex: 6 },
        speed: 4,
        folioId: "framing",
        imgPath: require("../works/framing/diagram.gif"),
        width: "26vw"
    },
    {
        position: { top: '4vw', right: '5vw', zIndex: 7 },
        speed: -6,
        folioId: "quad",
        imgPath: require("../works/quad/1.jpg"),
        width: "20vw"
    },
    {
        position: { top: '68vw', left: '40vw', zIndex: 7 },
        speed: -2,
        folioId: "inbetween",
        imgPath: require("../works/inbetween/3.gif"),
        width: "18vw"
    },
    {
        position: { top: '48vw', left: '6vw', zIndex: 1 },
        speed: -20,
        folioId: "curved",
        imgPath: require("../works/curved/3.jpg"),
        width: "24vw"
    },
];
