import { get } from 'lodash'
import { folioMap } from '../works/data'
import 'overlayscrollbars/overlayscrollbars.css';
import { OverlayScrollbarsComponent } from "overlayscrollbars-react"
import { PageContext } from '../pageContext';
import { useContext } from 'react';

const Desc = props => {

    const { activeFolio } = useContext(PageContext);

    const textHTML = {__html: get(folioMap, `${activeFolio}.text`)}
    return (
        <>
            <OverlayScrollbarsComponent
                element="div"
                className="main-container"
                // options={{ scrollbars: { theme: 'os-theme-binar' } }}
                defer
            >
                <div className="folio-desc">
                    <div className="folio-desc-text">
                        <div dangerouslySetInnerHTML={textHTML}></div>
                    </div>
                    <div className="folio-desc-right-space"></div>
                </div>
            </OverlayScrollbarsComponent>
        </>
    )
}

export default Desc