import { useContext } from 'react';
import { PageContext } from '../pageContext';
import Quadrii from '../quadrii'
import './index.css'

const Menu = props => {


  const { currentPage, setCurrentPage } = useContext(PageContext);
  const { color } = props
  const textColor = { color }

  let menuBar = (
    <div className="menu-bar">
      <div className="menu-left clickable sinkable" onClick={() => setCurrentPage('home')}>
        <Quadrii seed={6567} size={50} color={color} />
        <h2 className="site-title" style={textColor}>Shiyu Yin</h2>
      </div>
      <div className="menu-right">
        <h2 style={textColor}>
          <span className={`menu-item clickable sinkable ${currentPage === 'about' && 'active'}`} onClick={() => setCurrentPage('about')}>About</span>
          <span>&nbsp;/&nbsp;</span>
          <span className={`menu-item clickable sinkable ${currentPage === 'links' && 'active'}`} onClick={() => setCurrentPage('links')}>Links</span>
        </h2>
      </div>
    </div>
  )

  return menuBar
}

export default Menu