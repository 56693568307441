import { get } from "lodash"
import { useContext } from 'react';
import { folioMap } from "../works/data"
import "/node_modules/slick-carousel/slick/slick.css";
import "/node_modules/slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import './index.css'
import { PageContext } from "../pageContext";

const Carousel = props => {

  const { activeFolio } = useContext(PageContext);
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    waitForAnimate: false,
    arrows: false,
    cssEase: 'cubic-bezier(.3,.46,.03,1)'
  };

  let imageFileNames = get(folioMap, `${activeFolio}.imagesPath`, [])
  let images = imageFileNames.map((fileName, i) => (
    <div key={fileName + i} className="single-slide">
      <img
        className="rolling-img"
        src={require(`../works/${fileName}`)}
      />
    </div>
  ))
  let carousel = (
    <Slider {...sliderSettings}>
      {images}
    </Slider>
  )

  return carousel
}

export default Carousel