import React, { createContext, useState } from 'react';

export const PageContext = createContext();

export const PageContextProvider = ({ children }) => {
  const [activeFolio, setActiveFolio] = useState('');
  const [currentPage, setCurrentPage] = useState('home');

  return (
    <PageContext.Provider value={{ activeFolio, setActiveFolio: newFolio => {
      setActiveFolio(newFolio);
      if (newFolio) {
        document.body.classList.add('modal-open');
      } else {
        document.body.classList.remove('modal-open');
      }
    }, currentPage, setCurrentPage }}>
      {children}
    </PageContext.Provider>
  );
};
