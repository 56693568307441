import { get } from 'lodash'
import { folioMap } from '../works/data'
import 'overlayscrollbars/overlayscrollbars.css';
import ArrowSvg from '../assets/arrow.svg'
import FolioDesc from './desc'
import './index.css'
import Carousel from '../carousel';
import { PageContext } from '../pageContext';
import { useContext } from 'react';

const FolioDialog = props => {

    const { activeFolio, setActiveFolio } = useContext(PageContext);
    const introHTML = { __html: get(folioMap, `${activeFolio}.intro`) }
    const title = get(folioMap, `${activeFolio}.title`, "What?")
    const subtitle = get(folioMap, `${activeFolio}.subtitle`)

    return (
        <div className="folio-dialog-container">
            <div className="top-left">
                <div className="back-btn clickable sinkable" onClick={() => setActiveFolio(undefined)}>
                    <img className="arrow" src={ArrowSvg}></img>
                </div>
                <h2 className='folio-title'>{title}</h2>
                {subtitle && <h5 className='folio-subtitle'>{subtitle}</h5>}
                <p className="folio-desc-intro small-gill" dangerouslySetInnerHTML={introHTML}></p>
            </div>
            <div className="right">
                <Carousel />
            </div>
            <div className="bottom-left">
                <FolioDesc />
            </div>
        </div>
    )
}

export default FolioDialog