import { useState, useEffect } from 'react';
import './index.css'

const getMotionOffset = (t, seed, speed, randomness) => {
    const radius = 10;
    const freqs = [0.0523, 0.0453, 0.0564, 0.0499, 0.0508, 0.0412, 0.0399, 0.0533] // frequency of the sine wave
    const ts = [123, 352, 423, 531, 345, 98, 153, 853, 431, 4356, 23, 234]
    const amplitude = 0.1 * randomness; // amplitude of the sine wave
    let x = 0
    let y = 0
    freqs.forEach((freq, i) => {
        x += Math.sin((t + ts[i]) * freq * speed + seed) * radius * amplitude
        y += Math.cos((t + ts[i + 1]) * freq * speed + seed) * radius * amplitude
    })
    return { x, y };
}

const Quadrii = props => {
    const { seed = 0, size = 15, speed = 0.3, randomness = 1, color = 'black' } = props
    const r = 10
    const sizeOfFrame = 100
    const sizeOfQuadrii = 50
    const offset = (sizeOfFrame - sizeOfQuadrii) / 2
    const frameTime = 10
    const standardCoord = [
        [offset, offset],
        [offset + sizeOfQuadrii, offset],
        [offset + sizeOfQuadrii, offset + sizeOfQuadrii],
        [offset, offset + sizeOfQuadrii]
    ]
    const [time, setTime] = useState(0);
    const points = standardCoord.map((point, i) => {
        let { x, y } = getMotionOffset(time, seed + i, speed, randomness)
        return [
            point[0] + x,
            point[1] + y,
        ]
    })
    useEffect(() => {
        const interval = setInterval(() => {
            setTime(time => time + 1);
        }, frameTime);
        return () => clearInterval(interval);
    }, []);


    return (
        <div className="quadrii-container" style={{ width: size, height: size }}>
            <div className="quadrii" style={{ width: size * 2, height: size * 2, top: -size * 0.5, left: -size * 0.5 }}>
                <svg className="svgii" width={size * 2} height={size * 2} viewBox="0 0 100 100">
                    <polygon
                        points={`${points[0][0]},${points[0][1]} ${points[1][0]},${points[1][1]} ${points[2][0]},${points[2][1]} ${points[3][0]},${points[3][1]}`}
                        fill={color}
                    >
                    </polygon>
                </svg>
            </div>
        </div>
    )
}

export default Quadrii