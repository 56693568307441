import { useContext } from 'react';
import Menu from './menu'
import FolioWall, { FolioWallMobile } from './folio-wall'
import FolioDialog from './folio-dialog';
import './App.css';
import About from './about';
import Links from './links';
import { PageContext } from './pageContext';

function App() {

  const { activeFolio, currentPage } = useContext(PageContext);

  return (
    <div className={`app`}>
      {currentPage === 'home' && (
        <div className='outskirt home-outskirt' style={{ background: 'black' }}>
            <Menu color="white" />
            <FolioWall />
            <FolioWallMobile />
        </div>
      )}
      {currentPage === 'about' && <About />}
      {currentPage === 'links' && <Links />}
      {activeFolio && <FolioDialog />}
    </div>
  );

}

export default App;
