import { SVG } from '@svgdotjs/svg.js'
import {
    random
} from "@georgedoescode/generative-utils";
import * as geometric from "geometric";
import polygonClipping from "polygon-clipping";
import concaveman from "concaveman";
import {
    createHobbyBezier
} from "hobby-curve";
import { pointsOnBezierCurves } from "points-on-curve";
import findIntersections from "sweepline-intersections";
import './index.css';
import { useEffect, useRef } from "react";



const CutoutsTexture = props => {

    const {style = {}, foreground = 'white', background = 'black'} = props
    const textureRef = useRef(null)

    useEffect(() => {
        if (!textureRef.current.hasChildNodes()) {
            const width = 900;
            const height = 600;
    
            const svg = SVG()
                .viewbox(0, 0, width, height)
                .addTo(textureRef.current)
                .attr("preserveAspectRatio", "xMidYMid slice");
            const shapes = svg.group();
            const texture = svg.group();
    
            svg
                .rect(width, height)
                .stroke({
                    width: 0,
                    color: background
                })
                .fill("none");
    
            let polygons = [];
            let colors = [];
    
            function run() {
                polygons = [];
    
                for (let i = 0; i < 512; i++) {
                    const area = random(10000, 50000, 1, 100000, 1);
                    const container = geometric.polygonRandom(random(6, 12), area, [
                        random(0, width),
                        random(0, height)
                    ]);
                    const bounds = geometric.polygonBounds(container);
    
                    const points = [];
    
                    const d = random(24, 128, 1, 32, 1);
                    const p = 128;
    
                    for (let i = 0; i < p; i++) {
                        const point = [
                            random(bounds[0][0], bounds[1][0], 1),
                            random(bounds[0][1], bounds[1][1], 1)
                        ];
    
                        if (
                            geometric.pointInPolygon(point, container) &&
                            points.every((compare) => pointDist(...compare, ...point) > d)
                        ) {
                            points.push(point);
                        }
                    }
    
                    let h = concaveman(points, 0.5);
                    h = h.slice(0, h.length - 1);
    
                    if (h.length < 3) continue;
    
                    const polygon = [];
                    const curvePoints = createHobbyBezier(
                        h.map((p) => ({ x: p[0], y: p[1] })),
                        {
                            tension: 1,
                            cyclic: true
                        }
                    ).map((b, index, arr) => {
                        const startPoint =
                            index === 0 ? { x: h[0][0], y: h[0][1] } : arr[index - 1].point;
    
                        const points = pointsOnBezierCurves(
                            [
                                [startPoint.x, startPoint.y],
                                [b.startControl.x, b.startControl.y],
                                [b.endControl.x, b.endControl.y],
                                [b.point.x, b.point.y]
                            ],
                            16
                        );
    
                        polygon.push(...points);
                    });
    
                    const intersections = findIntersections({
                        type: "Polygon",
                        coordinates: [polygon]
                    });
    
                    if (intersections.length) continue;
    
                    if (
                        !polygons.some(
                            (compare) =>
                                polygonClipping.intersection([polygon], [compare]).length > 0
                        )
                    ) {
                        polygons.push(polygon);
                    }
                }
    
                colors = polygons.map((p) => [background, foreground, background][random(0, 2, 1)]);
    
                // setTimeout(() => {
                //     run();
                // }, 4000);
            }
    
            (function raf(d) {
                shapes.clear();
    
                polygons.forEach((p, i) => {
                    const copy = p.map((v) => {
                        if (random(0, 1) > 0.5) {
                            return [v[0] + random(-2, 2), v[1] + random(-2, 2)];
                        }
                        return v;
                    });
    
                    const shape = shapes.polygon(copy).fill(colors[i]).scale(0.9).stroke({
                        width: 4,
                        color: foreground
                    });
                });
    
                texture.clear();
                for (let i = 0; i < 1024; i++) {
                    texture
                        .circle(random(1, 3))
                        .cx(random(0, width))
                        .cy(random(0, height))
                        .fill([background, foreground][random(0, 1, 1, 1)]);
                }
    
                setTimeout(() => {
                    requestAnimationFrame(raf);
                }, 1000 / 10);
            })();
    
            run();
    
            function pointDist(x1, y1, x2, y2) {
                const a = x1 - x2;
                const b = y1 - y2;
    
                return Math.sqrt(a * a + b * b);
            }
        }
    }, [foreground, background])

    return (
        <div className="texture-container" ref={textureRef} style={{background, ...style}}>
        </div>
    )
}

export default CutoutsTexture

