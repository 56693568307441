export const folioMap = {
    duplaza: {
        title: 'DuPlaza',
        imagesPath: [
            "1.jpg",
            "2.jpg",
            "3.jpg",
            "4.jpg",
            "5.png",
            "6.png",
            "7.png",
            "8.png",
            "9.jpg",
            "a.jpg",
            "b.png",
            "c.jpg",
            "d.jpg",
            "e.png",
            "f.jpg",
            "g.jpg",
            "h.jpg",
            "i.png",
            "j.jpg",
            "k.jpg",
            "l.jpg"
        ].map(filename => `duplaza/${filename}`),
        intro: '203 Studio / group work / 2023<br />Instructor: Daniel Spiegel<br />Teammate: Zesen Zhang',
        text: `
            <p>Situated at a pivotal location in San Francisco, where Bernal Cut railway and San Jose Ave. major road way diverge from Mission St., the former gas station site is reimagined as a community center, complete with EV charging facilities. This project aims to redefine the intersection between transportation infrastructure and community engagement, serving as a key inflection point in this dynamic area. </p>
            <p>The name "Duplaza" merges "Duplex" and "Plaza". Characterized with two mutually-interacted open spaces - an upper plaza designed for indoor events and a lower plaza serving as a civic playground - the Duplaza Community Center offers two different degrees of publicness for dynamic social gatherings. Two plazas are synthesized into one spatial medium to encourage citizens to meet, rest, play, eat, read, or participate in any activity that contributes to placemaking. The permeable ground floor, the folded proportional facade, and the slanted roof that steps up in response to the topography all formally juxtapose with the site and its surrounding fabric, meanwhile maintaining a welcoming and lively presence at the pedestrian level. </p>
            <p>The lower plaza hosts a porous, semi-outdoor environment accessible from all directions, featuring programs such as a café, a brunch restaurant, and a bike kitchen.  Steps and platforms with greenery and outdoor dining areas invite people to meander through. In contrast, the upper plaza is designed as a long-span space with minimal divisions to promote a sense of fluidity and openness accommodating an event hall, a library, and a gallery. To support this 120-foot column-free space, the project deploys a tension cable truss system with a "fish-belly" central truss, which not only optimizes the stress state of the compression member (the arch), but also conserves net floor height at the ceiling shift point. This structural lightness introduces a sense of tension in the double-height large space, adding an elegant gesture to the design. </p>  
            <p>Conducted in an Integrated Design Studio, the project incorporates mechanical, structural, environmental, and life-safety strategies, ensuring a holistic approach to its design and functionality.</p>      
        `
    },
    inbetween: {
        title: 'In-Between',
        subtitle: 'Reshaping Privacy and Community in Collective Living through Transitional Architectural Elements',
        imagesPath: [
            "1.jpg",
            "2.png",
            "3.gif",
            "4.jpg",
            "5.jpg",
            "6.jpg",
        ].map(filename => `inbetween/${filename}`),
        intro: '204A Thesis Seminar / individual work / 2023 <br />Instructor: Neyran Turan',
        text: `
            <p>Domestic space has always evolved in response to societal transformations. With shifts in lifestyle, work dynamics, and family structure, there is a growing misalignment between our contemporary life and the housing traditions of the past, which prioritized shelter, privacy, and independence as primary desires. The increasing demand of communal life in urban sicario urge us to rethink, renegotiate, reformulate the current definitions of community and privacy. </p>
            <p>This thesis project intends to explore the transformative potential of transitional architectural elements in reshaping privacy and community within the realm of collective living. By reevaluating the role of the in-between spaces – the doors, the windows, the walls, the passageways, and pieces of furniture that compose the thresholds of our daily lives – we can forge a new understanding of collective living that challenges the binary notion of individual and communal spaces and celebrates spatial ambiguity and flexibility. </p>
            <p>Bernard Tschumi once said, “Architecture is defined by the actions it witnesses as much as by the enclosure of its walls.” He argued that movement and event are two essential components of architectural space. Movements within the in-between space can be particularly dynamic, encompassing both the motion of architectural elements and the movement of inhabitants. By focusing on the movements around these interstitial spaces, the project aims to reveal how these elements function not only as physical dividers, but also as active mediators that shape human interactions, experiences, and even the concept of dwelling.</p>
            <p>For instance, in Communal Villa, a project by Dogma and Realism Working Group, each living cell is equipped with an ‘inhabitable wall,’ a moveable element that encapsulates all the necessities for living: storage, bathroom, and sleeping alcove with book shelves. This inhabitable wall acts as a transitional element that fluidly connects the private and shared space. </p>
            <p>The thesis project promotes an inverse design approach that begins from the minuscule elements and use these elements as spatial generators. For instance, it can start with a wide door that open or close in three positions. Then, this partition element can be to generate a co-living space surrounding by 4 living units. Each room has several doors, and different ways of connections and arrangements are enabled by these doors. Finally, by aggregating and reorganizing this modular unit, this spatial experiment creates ambiguity between individual and communal spaces and enhances the multifunctionality of co-living. </p>
            <p>According to Robin Evans, the elements in architectural plans “are employed first to divide and then selectively to re-unite inhabited space, which describes “the nature of human relationships.” By designing through these elements and their juxtapositions with the spaces they connect, the idea is to go further than mere space sharing and delve deeper into how collective urban living can be socially codified, shaping not just physical spaces, but the very nature of our interactions within them. </p>        
            </br></br>
            <p><b>BIBLIOGRAPHY</b></p>
            <p>· Dogma and Realism Working Group, Communal Villa: Production and Reproduction in Artist's Housing, 2015 </p>
            <p>· Robin Evans, Figures, Doors and Passages, 1978 </p>
            <p>· Bernard Tschumi, Architecture and Disjunction, 1994 </p>
            <p>· Susanne Schmid, A History of Collective Living: Models of Shared Living, 2019 </p>
            <p>· Pier Vittorio Aureli, Leonard Ma, Mariapaola Michelotto, Martino Tattara, and Tuomas Toivonen, Promised Land: Housing from Commodification to Cooperation, 2019 </p>
            <p>· Dominique Boudet, New Housing in Zurich: Typologies for a Changing Society, 2017 </p>
            <p>· Dolores Hayden, Seven American Utopias: The Architecture of Communitarian Socialism, 1976 </p>
            <p>· Mark Jarzombek, Corridor Spaces, 2010 </p>
            <p>· Claudia Hildner, Future Living : Collective Housing in Japan, 2014 </p>
            <p>· Katy Chey, Multi-Unit Housing in Urban Cities: From 1800 to Present Day, 2018 </p>
        `
    },
    audi: {
        title: 'Regional Auditorium',
        imagesPath: 'diagram.jpg,site01.jpg,site02.jpg,render01.jpg,Plans-01.jpg,Plans-04.jpg,Plans-07.jpg,Plans-08.jpg,section-02.jpg,section-01.jpg,09.jpg,05.jpg,render02.jpg,render03.jpg'
            .split(',').map(path => `audi/${path}`),
        intro: '200B Studio / individual work / 2022 <br /> Instructor: Andrew Atwood',
        text: `
            <p>This project is an 8-story, 30,000-square-foot auditorium building in Oakland with multiple circulation systems and a variety of supporting programs, including commercial space, a restaurant, dressing rooms, rehearsal space, offices, meeting rooms, and storage. The design focuses on circulation as a tool for organizing spatial order. </p>
            <p>Following the logic of several vertical cuts through the box-shaped building created by two intersecting volumes - a wedge and a trapezoid - the hexagonal auditorium is located in the center of the building, and two sets of stairs are placed on either side of the auditorium. The design logic of vertical cuts extends to the openings and glazing on the building’s periphery, reflecting the internal spatial divisions on the external facades. </p>
            <p>The plans are consistent across floors: from the center to the periphery, the circulation spaces are sandwiched radially between the served spaces. Slightly tilted angles of the vertical cuts and shifting staircases introduce subtle variations on each floor, resulting in clear yet dynamic sections. On the lower levels, the hexagonal openings create double-height spaces for the lobby and staff lounge, while also hinting at the auditorium’s configuration above.</p>
            <p>Three types of surface material qualities enhance the spatial design through natural light and shading. The glazing and metallic opaque materials echo the cuts, while a third type of translucent condition using rotating louvers wraps around the building in certain areas, adding horizontal rhythms.</p>
        `
    },
    agri: {
        title: 'Agritopia',
        imagesPath: '12689x3754'.split('').map(path => `agri/${path}.jpg`),
        intro: '201 Studio / individual work / 2022<br />Instructor: René Davids',
        text: `
            <p>Based on the site analysis of the Jack London Area in Oakland, this project focuses on food production and distribution and their relationship to housing. The Agri-topia community aims to merge agriculture and housing by creating a shifting multi-layered system where different food-related activities can take place within the layers.</p>
            <p>Located at the intersection of old and new, the newly added building is required to connect and balance the paradoxical and complex nature of the site itself. Built on top of the existing buildings, the middle layer will be a mixed-use greenhouse with commercial programs such as a farmers' market, restaurants, and a horticultural experience center. Above the greenhouse is the residential layer of modular housing. On the rooftops are the agricultural planting layers, which are able to mitigate the urban heat island effect and regulate the interior temperature of the apartments.             </p>
            <p>The apartment is a zigzag volume with an atrium, moving staircases, and platforms for community activities. The units are constructed with thickened walls that sweep all programs to one side, creating a more flexible and open living space for the targeted young Bay Area working class. There are also terraces in each unit for small-scale vegetable gardening or daily planting. This project rethinks how we formulate our built environments and food production so that community residents interact through various activities and pass through green spaces to access their homes.</p>
        `
    },
    framing: {
        title: 'Framing & Unframing',
        imagesPath: 'diagram.gif,output7.jpg,diagram_model_1.jpg,output5.jpg,output6.jpg,output3.jpg,gif.gif,plan-01.jpg,plan-02.jpg,plan-03.jpg,plan-04.jpg,plan-05.jpg,plan-06.jpg,plan-07.jpg,section-02.jpg,section-01.jpg,output10.jpg,output4.jpg,axo_1.jpg,oblique.jpg,sectional_oblique_bw.jpg'
            .split(',').map(path => `framing/${path}`),
        intro: '200A Studio / individual work / 2021 <br /> Instructor: Marcel Sanchez Prieto',
        text: `
            <p>This project explores the relationship of mass and void, and also the multi-directionality derived from a pinwheel shape. It starts with a three-dimensional pinwheel that extends and frames spaces in multi directions.</p>
            <p>The mix-used building, designed for a family of four and a community kids center, differentiates the heights of the private and public areas to create a dynamic interplay between mass and void.</p>
            <p>The trajectorial language of the form is developed into an intertwining staircase system that blends with the building and landscape. The elevations and floor plans emphasize the tension between mass and void, while the sections highlight the relationship between spaces with opposite qualities.</p>
            <p>Through engagement of various social, intellectual and cultural contexts of the site, programs, and users, this small-scaled building experiments on the intricacy and complexity of spatial experience brought about <em>framing</em> and <em>unframing</em>.</p>
        `
    },
    curved: {
        title: 'Curved City',
        imagesPath: '1268qety3a45io'.split('').map(path => `curved/${path}.jpg`),
        intro: '201 Studio / individual work / 2022<br />Instructor: René Davids<br />Teammate: Yining Ma, Chiara Noppenberger, Nanxin Sun',
        text: `
            <p>This project is an urban-scale planning of 12 blocks of the Jack London Area in Oakland that evolves around the idea of integrating food and housing while being utopian. By mapping the current and proposed scheme, we found improvements in housing capacity, greenery, water collection, and traffic organization. </p>
            <p>The introduction of the curvilinear is derived from the shape of the columns that serve as water collectors. Agricultural planting has a close relationship with water treatment and recycling, so we were interested in forming this internal system for agricultural water recycling while having a formal language that unifies as a community. </p>
            <p>The concept of shifting layers allows areas with different programs and functionalities to spatially being merged, while maintaining their own independence. Different layers shift and interlock with one another, vitalizing the community both vertically and horizontally. </p>        
        `
    },
    quad: {
        title: 'Quadrilateral Dance',
        imagesPath: '1xwvu78943abdf56'.split('').map(path => `quad/${path}.jpg`),
        intro: '200B Studio / group work / 2022 <br />Instructor: Andrew Atwood<br />Teammate: Paola Gutierrez',
        text: `
            <p>This project is a month-long exercise on <em>structure</em> both as the physical support of a building and, more importantly, as the organizational ideas that bring order and hierarchy to a building's form, space, program, and materiality. </p>
            <p>In this 4-story City Market building on a single lot in San Francisco, two structural column grids are placed inside the building in a figure-ground way, separated by a circle. To break away from the grid systems, we introduced two intersecting quadrilateral volumes that move and rotate against each other on each floor, creating configurations that suit different uses in different areas of the building. Dynamic spaces on either side of the exterior walls are shaped by the collision of the grid system and the quadrilateral freeform system. </p>
            <p>When we think about our audience, we want the building to accommodate weekday workers as well as weekend families and tourists. We did this by keeping the interior spaces rigid and orthogonal, with amenities for convenience, juxtaposed with the whimsical and free exterior formed by quadrilateral volumes. Where the interior divisions meet the quadrilateral exterior wall, they become window openings, similar to where the exterior walls meet the site boundary and become openings. </p>
            <p>In response to the main volume to the north, a semi-outdoor staircase, also formed by two sets of dancing quadrilaterals, leads people from the main street to the flexible market spaces on each floor. </p>
        `
    }
}

export const folioIds = Object.keys(folioMap)
export const folioList = Object.values(folioMap)
